@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700&display=swap');

$bg-color: #f1f1f1;
$primary-bg-color: #fefefe;
$primary-color: #000;
$highlight-color: #0000ff;
$light-color: #fff;
$border-color: #ddd;
$ff-primary: 'Lato';
$ff-heading: 'Montserrat';
$default-font-size: 13pt;
$font-size-14: 14pt;
$font-size-15: 15pt;
$font-size-16: 16pt;

@mixin break {
  thead {
    display: none;
  }

  tr {
    display: block;
    margin-bottom: 5px;
  }

  td {
    display: block;
    position: relative;
    padding-left: 160px;
    text-align: left;
    border-bottom: 0;

    &:last-child {
      border-bottom: 1px solid $border-color;
    }

    &::before {
      content: attr(data-heading);
      position: absolute;
      top: 0;
      left: 0;
      width: 150px;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: #333333;
      color: $light-color;
      padding: 0 5px;
      justify-content: left;
    }
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

body {
  font-family: $ff-primary;
  font-size: $default-font-size;
  font-weight: 400;
  font-style: normal;
  overflow-y: scroll;
}

pre {
  white-space: pre-wrap;
  word-break: keep-all;
  font-family: $ff-primary !important;
  font-size: $font-size-14;
}

input {
  font-size: 16px !important;
}

.Montserrat-font {
  font-family: $ff-heading;
}

h1 {
  font-family: $ff-heading;
  font-weight: bold;
}

h2 {
  font-family: $ff-heading;
  font-weight: bold;
}

h3 {
  font-family: $ff-heading;
  font-weight: bold;
}

h4 {
  font-family: $ff-heading;
  font-weight: bold;
}

h5 {
  font-family: $ff-heading;
  font-weight: bold;
}

h6 {
  font-family: $ff-heading;
  font-weight: bold;
}

a,
a:active,
a:visited,
a:focus,
a.hover {
  text-decoration: none !important;
}

.banner-holder {
  margin-top: 70px !important;
}

.calculate-route:disabled {
  background-color: gray !important;
  border: 1px solid #666666;
}

.popover {
  font-family: $ff-primary;
  font-size: $font-size-14;
  font-weight: 400;
  font-style: normal;
}

.popover h5 {
  font-size: $font-size-14;
}

.sj-no-margin {
  margin: 0px !important;
  padding: 0px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-top {
  padding-top: 20px !important;
}

.padding-left {
  padding-left: 20px !important;
}

.padding-left-10 {
  padding-left: 10px !important;
}

.padding-left-5 {
  padding-left: 5px !important;
  padding-right: 0px !important
}

.padding-right-5 {
  padding-right: 5px !important;
  padding-left: 0px !important
}

.sj-top-logo-holder {
  text-align: center;
  padding: 15px;
}

.margin-bottom-5 {
  margin-bottom: 3rem !important;
}

.sj-top-logo-holder img {
  width: 45%;
}

.sj-large-font {
  font-size: 16pt !important;
}

.sj-large-font-button,
.dropdown button {
  font-size: 15pt !important;
  font-weight: bold;
}

.sj-link-button-container {
  margin-bottom: 15px !important;
  overflow: hidden;
}

.sj-link-button-container a {
  display: flex;
  overflow: hidden;
  float: left;
  padding: 5px 10px;
  margin: 2px 2px;
  background-color: #0066FF;
  border: 1px solid #0066FF;
  border-radius: 0.25rem;
  color: #fff;
  white-space: nowrap;
}

.sj-link-button-container a:hover {
  background-color: #0000CC;
  color: #fff;
}

.sj-weather-season-button a {
  display: flex;
  overflow: hidden;
  float: left;
  padding: 5px 10px;
  margin: 4px;
  background-color: #0d6efd;
  border: 1px solid #0d6efd;
  border-radius: 5px;
  color: #fff;
  white-space: nowrap;
}

.sj-weather-season-button a:hover,
.sj-weather-season-button a.selected {
  background-color: #990000;
  border: 1px solid #660000;
  color: #fff;
}

.sj-red-bg {
  background-color: #CC0000;
  border: 1px solid #910000;
}

.sj-red-bg:hover {
  background-color: #910000;
  border: 1px solid #6e0000;
}

.sj-red-bg:focus {
  background-color: #910000;
  border: 1px solid #6e0000;
}

.sj-manu-button {
  display: inline-block;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000CC;
  border: 1px solid #fff;
  padding: 0.375rem 0.4rem;
  color: #fff;
  width: 100%;
}

.sj-blue-header,
.sj-blue-text {
  color: #0000CC;
}

.sj-light-blue-header,
.sj-light-blue-text {
  color: #0000FF;
}

.sj-lighter-blue-text {
  color: #0d6efd !important;
}

.sj-red-header,
.sj-red-text {
  color: #cc0000;
}

.sj-large-button-blue {
  font-family: 'Montserrat';
  font-weight: 700;
  padding: 10px;
  color: #fff;
  clear: both;
  font-size: 1.5rem;
  margin-top: 15px;
  background-color: #3366FF;
}

.sj-full-width {
  width: 100% !important;
}

.sj-10-width {
  width: 200px !important;
}

.float-left {
  float: left !important;
}

.no-flex {
  display: inline;
}

.gray-font,
.gray-font h4,
.gray-font h5,
.gray-font h6 {
  color: #999999 !important;
}

.sj-box {
  padding: 10px;
  color: #fff !important;
}

.sj-box-gray {
  background-color: #808080;
  border: 1px solid #808080;
}

.sj-box-dark-gray {
  background-color: #333333 !important;
  border: 1px solid #333333 !important;
}

.sj-box-light-gray {
  background-color: #cccccc;
  border: 1px solid #cccccc;
}

.sj-box-lighter-gray {
  background-color: #EEEEEE;
  border: 1px solid #EEEEEE;
}

.sj-box-lighter-blue {
  background-color: #85a4ff;
  border: 1px solid #85a4ff;
}

.sj-box-blue {
  background-color: #3366FF;
  border: 1px solid #3366FF;
}

.sj-box-dark-blue {
  background-color: #0000CC;
  border: 1px solid #0000CC;
}

.sj-box-red {
  background-color: #CC0000 !important;
  border: 1px solid #CC0000 !important;
}

.sj-box-light-red {
  background-color: #ffacac;
  border: 1px solid #ffacac;
}

.sj-box-green {
  background-color: #008000;
  border: 1px solid #008000;
}

.sj-round-half {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.sj-round-full {
  border-radius: 0.25rem;
}

.sj-round-half-large {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.sj-round-full-large {
  border-radius: 0.5rem;
}

.sj-thick-border {
  border: 2px solid #fff;
}

.sj-box-lighter-gray h4,
.sj-box-lighter-gray h5,
.sj-box-lighter-gray h6,
.sj-box-lighter-gray a,
.sj-box-lighter-gray a:active,
.sj-box-lighter-gray a:visited {
  color: #000 !important;
  margin: 0px;
}

.sj-box h4,
.sj-box h5,
.sj-box h6,
.sj-box a,
.sj-box a:active,
.sj-box a:visited {
  color: #fff;
  margin: 0px;
}

.sj-image-holder {
  padding: 5px !important;
}

.sj-image-holder img {
  width: 100%;
}

.sj-image-holder-now img {
  width: 80%;
}

.sj-mountain {
  background-image: url('./static-contents/mountainbackground.svg');
  /**/
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.sj-mountain img {
  width: 70% !important;
}

.daily-report-tile-container {
  width: 100%;
  padding: 0px;
}

.daily-report-tile {
  min-height: 150px;
  border: 1px solid lightgrey;
  box-shadow: 2px 2px 2px #eee;
  width: 100%;
}

.daily-report-tile-body {
  width: 100%;
  font-size: 17px;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.App {
  text-align: left;
  max-width: 1320px;
  margin: 0px auto 0px;
  background-color: $primary-bg-color;
  color: $primary-color;
  height: 100%;
  display: block;
  min-height: 500px;
}

.sj-content-holder {
  /*padding: 10px 2px;
  padding: 10px 5px;*/
  padding: 10px 10px;
  display: block;
  min-height: 500px;
}

.header {
  padding: 50px 15px;
  background-color: $bg-color;
  text-align: center;
  margin-bottom: 40px;

  h1 {
    font-size: 40px;
    font-weight: 300;
  }
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px !important;
  margin: 0px !important;
}

.nav-link {
  display: block;
  padding-left: 5px !important;
  padding-right: 5px !important;
  color: #fff !important;
  font-size: 17pt;
  font-family: $ff-heading;
}

.nav-link:hover {
  color: #fff !important;
}

.sj-sub-menu a {
  color: #0000cc !important;
}

.sj-sub-menu .nav-link {
  color: #0000cc !important;
  display: block;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: $font-size-14;
  font-weight: 600 !important;
  font-family: $ff-heading;
  background-color: #85a4ff;
  margin: 1px 1px 0px 1px;
}

.sj-sub-menu .nav-link:hover {
  background-color: #0000cc !important;
  color: #fff !important;
}

.sj-sub-menu .active {
  background-color: #0000cc !important;
  color: #fff !important;
}

.sj-footer .nav-link {
  font-size: 15pt;
  font-weight: 500;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.sj-footer .nav-link:hover {
  font-weight: 500;
  color: #85a4ff !important;
}

.nav-divider {
  padding: 0px;
  color: #fff;
  padding-top: 12px;
}

.navbar-dark {
  background-color: #333333 !important;
}

.navbar-dark .navbar-toggler {
  color: rgba(0, 0, 0, 1);
  border-color: rgba(0, 0, 0, 0.5);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

/* Navigation */
.sj-nav-bar {
  font-family: $ff-heading;
}

.sj-nav-bar-mobile {
  font-family: $ff-heading;
  margin: 0px !important;
  padding: 0px !important;
  border-bottom: 5px solid rgb(53, 53, 53);
}

.sj-menu-link {
  border-top: 7px solid transparent;
  transition: border-color 0.5s linear;
}

.sj-menu-link-Resort:hover,
.sj-menu-link-Resort-section {
  border-color: #0000CC;
}

.sj-menu-link-Resort:hover .nav-link,
.sj-menu-link-Resort-section .nav-link {
  color: #fff !important;
}

.sj-menu-link-Nows:hover,
.sj-menu-link-Nows-section {
  border-color: #0066FF
}

.sj-menu-link-Nows:hover .nav-link,
.sj-menu-link-Nows-section .nav-link {
  color: #fff !important;
}

.sj-menu-link-Guide:hover,
.sj-menu-link-Guide-section {
  border-color: #0099FF
}

.sj-menu-link-Guide:hover .nav-link,
.sj-menu-link-Guide-section .nav-link {
  color: #fff !important;
}

.sj-menu-link-Listing:hover,
.sj-menu-link-Listing-section {
  border-color: #FF0000
}

.sj-menu-link-Listing:hover .nav-link,
.sj-menu-link-Listing-section .nav-link {
  color: #fff !important;
}

.sj-menu-link-About:hover,
.sj-menu-link-About-section {
  border-color: #990000
}

.sj-menu-link-About:hover .nav-link,
.sj-menu-link-About-section .nav-link {
  color: #fff !important;
}

.sj-menu-link-Resort .dropdown-menu,
.sj-menu-link-Nows .dropdown-menu,
.sj-menu-link-Guide .dropdown-menu,
.sj-menu-link-Listing .dropdown-menu,
.sj-menu-link-About .dropdown-menu {
  background-color: #f0f0f0 !important;
  box-shadow: 2px 3px 3px gray;
  font-size: $font-size-14 !important;
}

.sj-menu-link-Resort .dropdown-item,
.sj-menu-link-Nows .dropdown-item,
.sj-menu-link-Guide .dropdown-item,
.sj-menu-link-Listing .dropdown-item,
.sj-menu-link-About .dropdown-item {
  color: #000;
  font-weight: 500 !important;
}

.sj-menu-link-Resort .dropdown-item:hover,
.sj-menu-link-Resort .active {
  background-color: #0000CC;
  color: #fff;
}

.sj-menu-link-Nows .dropdown-item:hover,
.sj-menu-link-Nows .active {
  background-color: #0066FF;
  color: #fff;
}

.sj-menu-link-Guide .dropdown-item:hover,
.sj-menu-link-Guide .active {
  background-color: #0099FF;
  color: #fff;
}

.sj-menu-link-Listing .dropdown-item:hover,
.sj-menu-link-Listing .active {
  background-color: #FF0000;
  color: #fff;
}

.sj-menu-link-About .dropdown-item:hover,
.sj-menu-link-About .active {
  background-color: #990000;
  color: #fff;
}

.normal-link {
  color: #0d6efd;
}

.normal-link,
.normal-link:active,
.normal-link:visited,
.normal-link:focus,
.normal-link.hover {
  text-decoration: none !important;
}

.normal-link:hover {
  color: #0a58ca;
  cursor: pointer;
}

/*.sj-menu-link-News:hover, .sj-menu-link-News-section {
	border-color: #DF7401
}

.sj-menu-link-News:hover .nav-link, .sj-menu-link-News-section .nav-link{
  color: #DF7401 !important;
}*/

.table-container {
  width: 100%;
  margin: 5px auto 5px auto;

  &__title {
    background-color: $highlight-color;
    color: $light-color;
    text-align: center;
    padding: 15px 0px 10px 0px;
    width: 100%;

    h2,
    h3 {
      font-weight: 600;
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;

    thead {
      tr {
        background-color: transparent;
      }
    }

    th {
      border: 1px solid $border-color;
      /*border-top: 0px;*/
      padding: 10px 5px 5px 5px;
      text-align: left;
      background-color: #333333;
      color: #fff;
    }

    /*th:first-child {
      border-left: 0px;
      border-top: 0px;    
      border-top-left-radius: 0.5rem;
    }

    th:last-child {
      border-right: 0px;
      border-top: 0px;    
      border-top-right-radius: 0.5rem;
    }*/

    th h4 {
      margin-top: 5px;
    }

    td {
      border: 1px solid $border-color;
      padding: 5px;
      text-align: left;
    }

    tr {
      &:nth-child(even) {
        background-color: $bg-color;
      }
    }

    &--break-lg {

      @media (max-width: 991px) {
        @include break;
      }
    }

    &--break-md {

      @media (max-width: 767px) {
        @include break;
      }
    }

    &--break-sm {

      @media (max-width: 575px) {
        @include break;
      }
    }
  }
}

.light-border {
  border: 1px solid $border-color;
}

.sj-data-table th {
  font-size: 13px !important;
  text-align: center;
  font-family: $ff-heading;
  border-top: 0px !important;
}

.sj-data-table th:first-child {
  border-left: 0px;
  border-top: 0px;
  border-top-left-radius: 0.5rem !important;
  text-align: left !important;

}

.sj-data-table th:last-child {
  border-right: 0px;
  border-top: 0px;
  border-top-right-radius: 0.5rem !important;
}

.sj-data-table td {
  font-size: 13px !important;
  text-align: center;
}

.sj-data-table td a {
  color: #000 !important;
}

.sj-data-table td:first-child a {
  color: #fff !important;
}

.td-table-header {
  font-family: $ff-heading;
  font-weight: bold;
  font-size: 1.0rem;
  text-align: center !important;
}

.td-first-column {
  font-size: .9rem;
  font-weight: bold;
  background-color: #0000CC;
  text-align: center !important;
  white-space: nowrap;
  color: #fff;
}

.td-first-column a {
  color: #fff;
}

.td-monthly-snowfall {
  font-size: .9rem;
  font-weight: bold;
}

.td-season-snowfall {
  font-size: .8rem;
}

.resort-open {
  background-color: #ff0000;
  color: #fff;
  text-align: center;
  width: 4.76%;
}

.empty-row {
  clear: both;
  margin: 20px 0px !important;
  overflow: none;
}

.empty-row .row {
  background-color: #f1f1f1;
  padding: 10px;
}

.sj-row {
  clear: both;
  overflow: none;
  margin: 20px 0px !important;
}

.white-border {
  border: 2px solid #fff !important;
}

.sj-row-no-margin-padding {
  clear: both;
  overflow: none;
  margin: 0px !important;
  padding: 0px !important;
}

.opening-time td {
  width: 4.75%;
}

.opening-time th {
  background-color: #F5F5F5;
  color: #000;
  text-align: center;
}

.sj-no-bg {
  background-color: transparent !important;
}

.sj-padding-left-10 {
  padding-left: 0px !important;
  padding-top: 15px !important;
  padding-bottom: 10px !important;
}

.sj-padding-right-10 {
  padding-right: 0px !important;
}

.sj-padding-left-12 {
  padding-left: 0px !important;
}

.sj-padding-right-12 {
  padding-right: 0px !important;
}

.sj-padding-left-15 {
  padding-left: 0px !important;
}

.sj-padding-right-15 {
  padding-right: 0px !important;
}

.sj-margin-right-10 {
  margin-right: 10px !important;
}

.sj-padding-left-5 {
  padding-left: 5px !important;
}

.sj-padding-right-5 {
  padding-right: 5px !important;
}

.sj-row-small-margin-padding {
  clear: both;
  overflow: none;
  margin: 5px 0px !important;
  padding: 5px !important;
}

.sj-row-header {
  clear: both;
  margin: 0px !important;
  padding-top: 30px !important;
}

.sj-row .row {
  background-color: #f1f1f1;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.sj-row .row div {
  /*padding: 5px !important;*/
}

.sj-row-100 {
  width: 100% !important;
  margin: 20px auto !important;
}

.sj-row-100-left {
  padding-left: 0px;
  padding-right: 0px;
}

.sj-row-100-right {
  padding-left: 0px;
  padding-right: 0px;
}

.sj-skier-snowboarder-left {
  padding-left: 0px;
  padding-right: 0px;
}

.sj-skier-snowboarder-right {
  padding-left: 0px;
  padding-right: 0px;
}

.sj-row-100 button {
  width: 100% !important;
  text-align: left;
}

.sj-row-80 {
  width: 100%;
  margin: 20px auto !important;
  padding-left: 5px;
  padding-right: 5px;
}

.sj-row-100 .dropdown-menu {
  max-height: 400px !important;
  overflow-y: auto;
  width: 100%;
}

.sj-row .row img {
  width: 60%;
}

.skier-snowboarder img {
  width: 60% !important;
}

.image-100 {
  width: 100% !important;
}

.sj-container {
  max-width: 1320px;
  margin: 0px auto;
}

.sj-container-padding-10 {
  /*padding:10px;*/
  text-align: left;
}

.sj-info-box {
  background-color: #3399FF;
  font-family: 'Montserrat';
  font-weight: 600;
  padding: 10px;
  color: #fff;
  border-radius: 0.25rem;
}

.sj-info-box-gray {
  background-color: #e7e7e7;
  padding: 10px;
  color: #000;
  border-radius: 0.25rem;
  margin: 15px 0px;
}

.sj-info-box-gray-small {
  background-color: #e7e7e7;
  padding: 10px;
  color: #000;
  border-radius: 0.25rem;
  margin: 15px 0px;
  font-size: $default-font-size;
}

.sj-bullet-box {
  background-color: #0066FF;
  font-family: 'Montserrat';
  font-weight: 700;
  padding: 10px;
  color: #fff;
  border-radius: 0.25rem;
}

.sj-no-bullets,
.sj-no-bullets-thin {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
}

.sj-no-bullets li {
  padding: 5px 0px;
}

.sj-no-bullets-thin li {
  padding: 2px 0px;
  text-align: left;
  font-size: $font-size-14;
}

.sj-no-bullets-vertical {
  list-style-type: none;
  margin: 0px 0px 25px 0px;
  padding: 0px;
}

.sj-no-bullets-vertical li {
  padding: 2px 0px;
}

.sj-no-bullets-vertical a {
  padding: 0px 10px;
}

.sj-no-bullets-horizontal {
  list-style-type: none;
  margin: 0px 0px 25px 0px;
  padding: 0px;
}

.sj-no-bullets-horizontal li {
  padding: 2px 0px;
}

/*************************/
/* Calendar */
.now-calendar-frame {
  width: 100%;
  min-height: 505px;
  border: 1px solid lightgrey;
  box-shadow: 2px 2px 2px #eee;
  float: left;
  margin: 10px;
  border-radius: 5px;
  position: relative;
}

.now-calendar-frame-header {
  font-size: 16pt;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  justify-content: space-between;
  /*background-color: #cccccc;*/
  background-color: #333333 !important;
  border: 1px solid #333333 !important;
  text-align: center !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.now-calendar-frame-header h4 {
  color: #fff;
}

.now-calendar-frame-body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 1px;
}

.now-calendar-day-header {
  width: 14.285%;
  height: 30px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #e7e7e7;
  background-color: #eee;
  font-size: $default-font-size;
  padding-top: 2px;
}

.now-calendar-day-body {
  width: 14.285%;
  height: 70px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #eee;
}

.now-calendar-snow-fall {
  height: 30px;
  width: 30px;
  background-color: #6cb5ff;
  border-radius: 50%;
  display: inline-block;
  clear: both;
  font-size: .7em;
  padding-top: 4px;
  cursor: pointer;
}

.empty-date {
  width: 14.285%;
  height: 70px;
  text-align: center;
}

.with-date {
  width: 14.285%;
  height: 70px;
  text-align: center;
  border: 1px solid #fff;
  background-color: #eee;
  padding-top: 20px;
}

.with-date-individual {
  width: 14.285%;
  height: 70px;
  text-align: center;
  border: 1px solid #fff;
  background-color: #eee;
}

.with-report {
  background-color: #0000CC;
  color: #fff;
}

.with-report-individual-0 {
  background-color: #0099FF;
  color: #fff;
  cursor: pointer;
}

.with-report-individual-9 {
  background-color: #0066ff !important;
  color: #fff;
  cursor: pointer;
}

.with-report-individual-19 {
  background-color: #0033CC;
  color: #fff;
  cursor: pointer;
}

.with-report-individual-49 {
  background-color: #0000aa;
  color: #fff;
  cursor: pointer;
}

.with-report-individual-50 {
  background-color: #000077;
  color: #fff;
  cursor: pointer;
}

.calendar-date-container {
  padding-top: 4px;
}

.calendar-snow-container {
  font-size: $default-font-size;
}

.popup-content {
  background-color: #fff;
  max-width: 276px;
  min-width: 250px;
  border-radius: .3rem;
  box-shadow: 0 .5rem 1rem rgba(#000, .15);
  border: 1px solid #bbb;
}

.popup-content-header {
  padding: 10px;
  border-bottom: 1px solid #bbb;
}

.popup-content-body {
  padding: 10px;
}

.sorted-column {
  background-color: #FFFDD0;
  font-weight: bold;
}

.sorted-column h5 {
  /*color: #fff;*/
}

.sorted-column-header {
  background-color: #3366FF !important;
}

.sj-first-column,
.sj-first-column a {
  font-size: 15pt;
  /*color: #000;*/
}

.next-prev-button-holder {
  padding: 15px;
  display: block;
  clear: both;
}

.sort-bar-holder {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
}

.info-box-text {
  font-size: $font-size-14 !important;
}

.search-filter-holder {
  text-align: left;
  width: 50%;
  float: left;
}

.search-filter-holder-full {
  text-align: left;
  width: 50%;
  float: left;
}

.sort-button-holder {
  text-align: right;
  width: 50%;
  float: left;
  display: block;
}

.space-above {
  margin-top: 20px;
}

.left-aligned {
  text-align: left !important;
}

.left-aligned-jma {
  text-align: center !important;
}

.right-aligned {
  text-align: right !important;
}

.center-aligned {
  text-align: center !important;
}

.hide-on-mobile {
  display: none;
}

.sj-top-menu {
  width: 100%;
}

.sj-top-menu .nav-link {
  margin-left: 25px;
}

.sj-top-menu .nav-link:focus {
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0) !important;
  -webkit-box-shadow: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.sj-mobile-header {
  background-color: #fff;
  width: 100%;
  padding-left: 10px;
}

.sj-mobile-logo {
  width: 70%;
  margin: 10px;
}

.sj-footer-text-holder {
  text-align: center !important;
}

.sj-footer-logo-holder {
  text-align: center !important;
}

.sj-para {
  margin: 20px 0px;
}

.sj-para-single-row {
  margin: 20px 0px;
  clear: both;
}

.sj-para-large {
  font-family: $ff-primary;
  margin: 20px 0px;
  font-size: $font-size-15;
}

.sj-para-image {
  float: right;
  width: 60% !important;
  margin-left: 10px;
}

.accommodation-first-photo {
  float: right;
  width: 65%;
  margin-left: 10px;
}

.sj-para-image img,
.accommodation-first-photo img {
  padding: 0px 0px 10px 0px !important;
}

.sj-para-map {
  float: right;
  width: 50%;
  margin-left: 10px;
}

.sj-para-map img {
  padding: 0px 0px 10px 10px;
  width: 100%;
}

.sj-next-page-button {
  padding: 12px;
  font-family: 'Montserrat';
  font-weight: 600;
  font-size: $font-size-15;
  background-color: #000080;
}

.sj-info-box .read-more {
  font-family: 'Montserrat';
  font-weight: 600;
  padding: 10px;
  color: #fff;
  clear: both;
  text-transform: capitalize;
  font-size: $default-font-size;
  margin-top: 15px;
}

.sj-table-container {
  width: 95%;
  margin: 20px 0px;
}

.sj-table,
.sj-table-home {
  width: 100%;
  border-collapse: collapse;
}

.sj-table-title {
  background-color: #0000ff;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.sj-table-container h3 {
  font-weight: 600;
}

/**/

.sj-table thead tr {
  background-color: transparent;
}

.sj-table th {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: center;
  font-size: $default-font-size;
}

.sj-table td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: left;
  vertical-align: top;
  font-size: $default-font-size;
}

.sj-table-home tr {
  background-color: transparent !important;
}

.sj-table-home td {
  border: none !important;
  padding: 2px;
  text-align: left;
  vertical-align: top;
  font-size: $default-font-size;
  background-color: transparent !important;
}

.sj-table-home td h6 {
  font-size: $default-font-size;
}

.sj-table tr:nth-child(even) {
  background-color: #f1f1f1;
}

.sj-table-2column tr td:first-child {
  width: 50%;
}

.sj-table-2column tr td:nth-child(2) {
  width: 50%;
}

.sj-table-3column tr td:first-child {
  width: 15%;
}

.sj-table-3column tr td:nth-child(2) {
  width: 45%;
}

.sj-table-3column tr td:nth-child(3) {
  width: 40%;
}

.half-and-float {
  width: 100%;
}

.sj-frame-header {
  font-size: $font-size-16;
  border: 1px solid rgb(53, 53, 53) !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  font-weight: bold;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: rgb(53, 53, 53) !important;
  margin: 5px 0px !important;
  padding: 5px !important;
  padding-left: 10px !important;
}

.sj-frame-body {
  /*padding-top: 5px !important;
  padding-bottom: 5px !important;*/
}

.sj-frame-header div+div {
  border-left: 1px solid $border-color;
}

.sj-frame-body div+div {
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}

.sj-frame-body div {
  border-bottom: 1px solid $border-color !important;
  border-left: 1px solid $border-color;
  padding: 8px;
}

.sj-data-table-holder>div:nth-child(even) {
  background-color: #fff;
}

.sj-frame-data {
  flex-wrap: wrap;
  margin: 2px 0px !important;
  padding: 3px !important;
  padding-left: 10px !important;
}

.sj-frame-data-resort {
  flex-wrap: wrap;
  margin: 2px 0px !important;
  padding: 3px !important;
  padding-left: 0px !important;
}

.sj-guide-frame-header h4,
.sj-guide-frame-header h5,
.sj-guide-frame-header h6,
.sj-guide-frame-header a,
.sj-guide-frame-header a:active,
.sj-guide-frame-header a:visited {
  color: #fff;
}

.sj-frame-header h4,
.sj-frame-header h5,
.sj-frame-header h6,
.sj-frame-header a,
.sj-frame-header a:active,
.sj-frame-header a:visited {
  color: #fff;
  margin: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ski-area-listing-holder {
  height: 1025px;
  overflow-y: auto;
}

// Listing Section
.sj-listing-frame-row {
  display: block;
}

.sj-listing-frame-holder {
  min-width: 400px;
  width: 100%;
  float: left;
  padding: 0px 10px;
  margin: 10px 0px;
}

.sj-listing-frame {
  width: 100%;
  min-height: 150px;
  border: 1px solid lightgrey;
  box-shadow: 2px 2px 2px #eee;
  background-color: #f5f6fa;
}

.sj-listing-frame-body {
  font-size: $font-size-16;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.sj-listing-frame-body .dropdown-menu {
  height: 370px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Guide Section */
.sj-guide-frame-row {
  display: block;
}

.sj-guide-frame-holder {
  min-width: 400px;
  width: 100%;
  float: left;
  padding: 0px 10px;
  margin: 10px 0px;
}

.sj-guide-frame {
  width: 100%;
  min-height: 150px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #eee;
  background-color: #f5f6fa;
  margin-bottom: 20px;
}

.sj-guide-frame-no-bottom-space {
  margin-bottom: 0px;
}

.sj-guide-frame-header {
  font-size: $font-size-16;
  border: 1px solid rgb(53, 53, 53) !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  background-color: rgb(53, 53, 53) !important;
  height: 70px;
}

.sj-guide-frame-body {
  font-size: $font-size-16;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

/* Graph Section */
.sj-graph-frame-row {
  display: block;
  clear: both;
}

.sj-graph-frame-holder {
  min-width: 400px;
  width: 100%;
  float: left;
  padding: 5px 10px;
  margin: 5px 0px;
}

.sj-graph-holder-left,
.sj-graph-holder-right {
  margin-left: 0px;
  margin-right: 0px;
}

.sj-graph-frame {
  min-height: 150px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #eee;
  background-color: #f5f6fa;
  margin-bottom: 20px;
}

.sj-graph-frame-no-bottom-space {
  margin-bottom: 0px;
}

.sj-graph-frame-header {
  font-size: $font-size-16;
  border: 1px solid rgb(53, 53, 53);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  font-weight: bold;
  padding: 10px 10px 5px 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
  background-color: rgb(53, 53, 53);
}

.sj-graph-frame-header h4,
.sj-graph-frame-header h5,
.sj-graph-frame-header h6,
.sj-graph-frame-header a,
.sj-graph-frame-header a:active,
.sj-graph-frame-header a:visited {
  color: #fff;
}

.sj-graph-frame-body {
  font-size: $font-size-16;
  font-weight: bold;
  padding: 10px 10px 10px 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: center;
}

.search-bar-frame {
  width: 100%;
  min-height: 150px;
  border: 1px solid lightgrey;
  box-shadow: 2px 2px 2px #eee;
  background-color: #FFFDD0;
  margin: 10px 10px;
}

.search-bar-frame-body {
  width: 100%;
  font-size: 17px;
  padding: 10px 10px 10px 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
  max-height: 450px;
  overflow-y: auto;
}

.bg-color-ski-area {
  background-color: #0000CC;
  transition-duration: .5s;
}

.ski-area-sub-menu,
.ski-area-sub-menu button {
  background-color: #0000CC !important;
  border-radius: 0.25rem !important;
}

.ski-area-sub-menu .dropdown-item:hover {
  background-color: #0000CC !important;
  color: #fff;
}

.bg-color-report {
  background-color: #0066FF;
  transition-duration: .5s;
}

.daily-report-sub-menu,
.daily-report-sub-menu button {
  background-color: #0066FF !important;
  border-radius: 0.25rem !important;
}

.daily-report-sub-menu .dropdown-item:hover {
  background-color: #0066FF !important;
  color: #fff;
}

.bg-color-accommodation {
  background-color: #FF0000;
  transition-duration: .5s;
}

.accommodation-sub-menu,
.accommodation-sub-menu button {
  background-color: #FF0000 !important;
  border-radius: 0.25rem !important;
}

.accommodation-sub-menu .dropdown-item:hover {
  background-color: #FF0000 !important;
  color: #fff;
}

.tabs {
  display: table;
  table-layout: fixed;
  width: 100%;
  -webkit-transform: translateY(5px);
  transform: translateY(5px);

  >li {
    transition-duration: .25s;
    /*display: table-cell;*/
    display: flex;
    list-style: none;
    text-align: center;
    padding: 10px 20px 18px 20px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    color: white;
    float: left;

    &:before {
      z-index: -1;
      position: absolute;
      content: "";
      width: 100%;
      height: 120%;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .8);
      /*background-color: rgba(0, 0, 204, .3);;*/
      -webkit-transform: translateY(100%);
      transform: translateY(100%);
      transition-duration: .25s;
      /*border-radius: 5px 5px 0 0;*/
    }

    &:hover {
      &:before {
        -webkit-transform: translateY(70%);
        transform: translateY(70%);
      }
    }

    >a {
      color: #fff;
    }

    &.active {
      color: rgb(80, 85, 90);

      &:before {
        transition-duration: .5s;
        background-color: white;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        border-radius: 5px 5px 0 0;
      }

      >a {
        color: rgb(80, 85, 90);
      }
    }
  }
}

.search-input-box {
  border: 1px solid #999;
  padding: 0.5rem;
  width: 65%;
  background-color: #F5F5F5;
}

.map-control-holder {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.map-control-holder input {
  width: 95%;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 400px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(43% + 1rem);
  position: absolute;
  z-index: 1000;
}

.suggestions li {
  padding: 0.5rem;
  background-color: #eeeeee;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #0000CC !important;
  color: #F5F5F5;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.sj-list-text-holder {
  padding: 0px !important;
  padding-top: 20px !important;
}

.sj-list-icon-holder {
  text-align: center !important;
  margin: 0px !important;
  padding: 0px !important;
}

.sj-list-icon-holder img {
  width: 60% !important;
}

.home-guide-section {
  margin: 25px 0px !important;
}

.sj-guide-frame-first,
.sj-bia-frame-first {
  padding-right: 0px !important;
}

.sj-guide-frame-middle,
.sj-bia-frame-middle {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.sj-guide-frame-last,
.sj-bia-frame-last {
  padding-right: 0px !important;
}

/*.sj-guide-frame-first{
  padding-right: 9px !important;
}

.sj-guide-frame-middle{
  padding-left: 9px !important;
  padding-right: 0px !important;
}

.sj-guide-frame-last{
  padding-right: 9px !important;
}*/

.sj-guide-frame-body img {
  width: 100% !important;
}

.sj-list-text-holder .table-container {
  margin: 0px !important;
}

.sj-map-andrew img {
  width: 80%;
}

.home-listing-holder-left,
.home-listing-holder-center1,
.home-listing-holder-center2,
.home-listing-holder-right {
  text-align: center !important;
}

.home-listing-holder-left img,
.home-listing-holder-center1 img,
.home-listing-holder-center2 img,
.home-listing-holder-right img {
  width: 60% !important;
}

.sj-50-box {
  width: 100%
}

.padding-left-5 {
  padding-left: 0px !important;
  padding-right: 0px !important
}

.padding-right-5 {
  padding-right: 0px !important;
  padding-left: 0px !important
}

.monthly-snow-fall th:first-child {
  text-align: center !important;
}

.monthly-snow-fall td {
  width: 14% !important;
}

.monthly-snow-fall td:first-child {
  width: 16% !important;
}

.overflow-x {
  overflow-x: auto;
}

/* Login and Register starts */

.sj-login {
  height: 100%;
  min-height: calc((100vh*.60));
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  margin-top: 30px;
}

.sj-wrapper {
  width: 75%;
  height: 100%;
  min-height: calc((100vh*.50));
  -webkit-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  box-shadow: 0px 5px 33px -21px rgba(66, 68, 90, 1);
  align-items: center;
  border-radius: 10px;
}

.sj-loginTitle {
  /*
  position: absolute;
  top: 200px;*/
  text-align: center;
  color: lightgray;
  margin-top: 25px;
  margin-bottom: 50px;
}

.sj-wrapper-inner {
  align-items: center;
  display: flex;
}

.sj-left,
.sj-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sj-center {
  height: 100%;
  min-height: calc((100vh*.50));
  display: flex;
  align-items: center;
  justify-content: center;
  position: flex;
  width: 40px;
}

/*.sj-wrapper .sj-row {
  display: flex !important;
  padding-left: 25px;
}*/

.sj-or {
  border: 2px solid lightgray;
  border-radius: 50%;
  padding: 10px;
  color: gray;
  background-color: white;
  font-weight: bold;
  z-index: 20 !important;
  position: absolute;
}

.sj-line {
  width: 2px;
  height: 100%;
  min-height: 250px;
  background-color: lightgray;
  margin: auto;
  z-index: 19;
  /**/
}

.sj-loginButton {
  width: 150px;
  padding: 15px 25px;
  border-radius: 5px;
  color: white;
  display: flex;
  align-items: center;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}

.sj-google {
  background-color: #df4930;
}

.sj-facebook {
  background-color: #507cc0;
}

.sj-github {
  background-color: black;
}

.sj-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Login and Register ends */

/* Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {

  .sj-guide-frame-first {
    padding-right: 9px !important;
  }

  .sj-guide-frame-middle {
    padding-left: 9px !important;
    padding-right: 0px !important;
  }

  .sj-guide-frame-last {
    padding-right: 9px !important;
  }

  .overflow-x {
    overflow-x: hidden;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .sj-bia-frame-first {
    padding-right: 12px !important;
  }

  .sj-bia-frame-middle {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .sj-bia-frame-last {
    padding-left: 12px !important;
    padding-right: 0px !important;
  }

  .sj-list-text-holder {
    padding-left: 20px !important;
    padding-top: 0px !important;
  }

  .sj-list-icon-holder {
    text-align: center !important;
    margin-right: 0px !important;
    padding: 0px !important;
  }

  .sj-list-icon-holder img {
    width: 100% !important;
  }

  .sj-para-large {
    font-size: $font-size-16 !important;
  }

  .sj-para-image,
  .sj-para-map {
    width: 50% !important;
  }

  .accommodation-first-photo {
    width: 50%
  }

  .sj-listing-frame-holder,
  .sj-guide-frame-holder {
    width: 50%;
  }

  .td-table-header {
    font-size: 1.1rem;
  }

  .td-monthly-snowfall,
  .td-first-column {
    font-size: 1.0rem;
  }

  .td-season-snowfall {
    font-size: .9rem;
  }

  .hide-on-mobile {
    display: block;
  }

  .padding-left-5 {
    padding-left: 5px !important;
  }

  .padding-right-5 {
    padding-right: 5px !important;
  }

  .ski-area-list td {
    width: 8%;
  }

  .sj-list-table th,
  .ski-area-list th {
    border-top: 0px !important;
    vertical-align: top !important;
    text-align: center !important;
  }

  .ticket-info th {
    text-align: left !important;
  }

  .sj-list-table th:first-child,
  .ski-area-list th:first-child {
    border-left: 0px;
    border-top: 0px;
    border-top-left-radius: 0.5rem !important;
    text-align: left !important;
  }

  .monthly-snow-fall th:first-child {
    text-align: center !important;
  }

  .sj-list-table td {
    vertical-align: top !important;
  }

  .monthly-snow-fall th:first-child {
    width: 16% !important;
  }

  .sj-accommodation-table th {
    text-align: left !important;
  }

  .sj-accommodation-table th:nth-child(2) {
    text-align: left !important;
  }

  .sj-accommodation-table td:nth-child(3),
  .sj-accommodation-table td:nth-child(4) {
    width: 12% !important;
    text-align: left !important;
  }

  .sj-snow-table td {
    width: 18% !important;
  }

  .sj-snow-table td:first-child {
    width: 46% !important;
  }

  .sj-list-table th:last-child,
  .ski-area-list th:last-child {
    border-right: 0px;
    border-top: 0px;
    border-top-right-radius: 0.5rem !important;
  }

  .ski-area-list td {
    text-align: center !important;
    vertical-align: top;
  }

  .ski-area-list td:first-child {
    width: 44%;
    text-align: left !important;
  }

  .home-listing-holder-left {
    margin: 10px !important;
    text-align: center !important;
  }

  .home-listing-holder-center1 {
    margin-left: 10px !important;
    text-align: center !important;
  }

  .home-listing-holder-center2 {
    margin-right: 10px !important;
    margin-left: 10px !important;
    text-align: center !important;
  }

  .home-listing-holder-right {
    margin-left: 10px !important;
    text-align: center !important;
  }

  .home-listing-holder-left img,
  .home-listing-holder-center1 img,
  .home-listing-holder-center2 img,
  .home-listing-holder-right img {
    width: 70% !important;
  }

  .sj-graph-holder-left {
    margin-left: 0px !important;
    margin-right: 10px !important;
  }

  .sj-graph-holder-right {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }

  .sj-skier-snowboarder-left {
    padding-left: 0px;
    padding-right: 5px;
  }

  .sj-skier-snowboarder-right {
    padding-left: 5px;
    padding-right: 0px;
  }

  .skier-snowboarder img {
    width: 100% !important;
  }
}

/* Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  body {
    font-size: $font-size-15 !important;
  }

  .sj-padding-right-10 {
    padding-right: 10px !important;
  }

  .sj-padding-left-10 {
    padding-left: 10px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .sj-guide-frame-first {
    padding-right: 12px !important;
  }

  .sj-guide-frame-middle {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }

  .sj-guide-frame-last {
    padding-left: 12px !important;
    padding-right: 0px !important;
  }

  .margin-bottom-5 {
    margin-bottom: 0rem !important;
  }

  .sj-para-large {
    margin: 25px 0px;
    font-size: $font-size-16 !important;
  }

  .sj-padding-left-12 {
    padding-left: 12px !important;
  }

  .sj-padding-right-12 {
    padding-right: 12px !important;
  }

  .sj-padding-left-15 {
    padding-left: 15px !important;
  }

  .sj-padding-right-15 {
    padding-right: 15px !important;
  }

  .sj-para-image,
  .sj-para-map {
    width: 30% !important;
  }

  .sj-para-map {
    width: 50% !important;
  }

  .accommodation-first-photo {
    width: 40%
  }

  .sj-next-page-button {
    padding: 15px;
    font-size: $font-size-16 !important;
  }

  .sj-para {
    margin: 25px 0px;
  }

  .sj-info-box .read-more {
    font-size: $font-size-15 !important;
    margin-top: 20px;
  }

  .sj-table-container {
    width: 50%;
  }

  .sj-50-box {
    width: 50%
  }

  .sj-50-box input {
    width: 100% !important;
  }

  .sj-table th,
  .sj-table td {
    font-size: $font-size-14;
  }

  .half-and-float {
    width: 50%;
    float: left;
  }

  .sj-graph-frame-holder {
    width: 50%;
  }

  .sj-box {
    padding: 15px;
  }

  .sj-image-holder img {
    width: 100%;
  }

  .sj-image-holder-now img {
    width: 75%;
  }

  .sj-row-80 {
    width: 80%;
  }

  .sj-td-50 {
    width: 50%;
  }

  .td-table-header {
    font-size: 1.25rem;
  }

  .td-monthly-snowfall,
  .td-first-column {
    font-size: 1.2rem;
  }

  .td-season-snowfall {
    font-size: 1.1rem;
  }

  .hide-on-mobile {
    display: block;
  }

  .nav-link {
    display: block;
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  .navbar {
    padding: 2px 15px;
  }

  .navbar-nav .dropdown-menu {
    margin-top: 0px !important;
  }

  .dropdown a::after {
    border: 0px;
    margin-left: 0px;
  }

  .sj-top-menu .nav-link {
    margin-left: 0px;
  }

  .left-aligned-jma {
    text-align: left !important;
  }

  .home-listing-holder-left {
    margin: 0px 15px 0px 0px !important;
  }

  .home-listing-holder-center1 {
    margin: 0px 10px 0px 5px !important;
  }

  .home-listing-holder-center2 {
    margin: 0px 5px 0px 10px !important;
  }

  .home-listing-holder-right {
    margin: 0px 0px 0px 15px !important;
  }

  .home-listing-holder-left img,
  .home-listing-holder-center1 img,
  .home-listing-holder-center2 img,
  .home-listing-holder-right img {
    width: 100% !important;
  }

  .sj-row-100-left {
    padding-left: 0px;
    padding-right: 10px;
  }

  .sj-row-100-right {
    padding-left: 10px;
    padding-right: 0px;
  }

  .sj-skier-snowboarder-left {
    padding-left: 0px;
    padding-right: 10px;
  }

  .sj-skier-snowboarder-right {
    padding-left: 10px;
    padding-right: 0px;
  }

  .modal-lg,
  .modal-xl {
    max-width: 1200px !important;
  }
}

/* Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

  .sj-listing-frame-holder,
  .sj-guide-frame-holder {
    width: 33.33%;
  }

  .sj-content-holder {
    padding: 10px 0px;
  }

  .td-table-header {
    font-size: 1.25rem;
  }

  .td-monthly-snowfall,
  .td-first-column {
    font-size: 1.2rem;
  }
}
#mapwrapper {
  /*font-family: Calibri;*/
  max-width: 2200px;
  margin: 0 auto;
  padding: 0 0;
  background-color: transparent;
  min-width: 220px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; 
}
#mapbase svg {
  max-height: 100%;
  width: 100%;
  margin: 0;
  -webkit-filter: drop-shadow( 1px 1px 2px rgba(0, 0, 0, 0) ); 
    filter: drop-shadow( 1px 1px 2px rgba(0, 0, 0, 0) );
}
#jpjstip {
  font: 21px/24px Trebuchet MS, Helvetica, Arial, sans-serif;
  display: none;
  max-width: 40%;
  padding: 5px;
  border: 5px solid #000000;
  color: #000000;
  z-index: 1000;
  float: left;
  position: absolute;
  background: rgba(265, 265, 265, 0.7);
  word-break: keep-all;
  box-shadow:1px 2px 4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow:1px 2px 4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow:1px 2px 4px rgba(0, 0, 0, 0.5);
}
#jpjstip p {
  margin: 0!important;
  color: #000000!important;
}
#jpjstip img {
  float: left;
  padding: 3px;
  max-width: 100%;
}
@media screen and (max-width: 320px){
  #mapbase svg {
    height:300px;
  }
}
@media screen and (max-width: 400px) and (min-width: 321px) {
  #mapbase svg {
    height:330px;
  }
}
@media screen and (max-width: 480px) and (min-width: 401px) {
  #mapbase svg {
    height:420px;
  }
}
@media screen and (max-width: 568px) and (min-width: 481px) {
  #mapbase svg {
    height:490px;
  }
}
@media screen and (max-width: 685px) and (min-width: 569px) {
  #mapbase svg {
    height:450px;
  }
}
@media screen and (max-width: 767px) and (min-width: 686px) {
  #mapbase svg {
    height:550px;
  }
}
@media screen and (min-width: 768px) {
  #mapbase svg {
    height:900px;
  }
}

.map-active:hover{
  fill: #ff0000;
}

.map-active:active{
  fill: #0099ff;
}

/*.map-inactive{

}

.map-inactive:hover{
  fill: #FF0000;
}*/
/* THIS IS TO HIDE AN AREA IF NEEDED
#jpjs2, #jpjsvn2 {
  display: none;
}*/
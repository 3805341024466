@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;600;700&display=swap');

.uploader-container {
  height: 200px;
  width: 95%;
  font-family: 'Lato';
  padding-top: 75pt;
}

.uploader {
  height: 20px;
  width: 350px;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.uploader--dot {
  animation-name: uploader;
  animation-timing-function: ease-in-out;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background-color: transparent;
  position: absolute;
  border: 2px solid white;
}

.uploader--dot:first-child {
  background-color: #990000;
  animation-delay: 0.5s;
}

.uploader--dot:nth-child(2) {
  background-color: #FF0000;
  animation-delay: 0.4s;
}

.uploader--dot:nth-child(3) {
  background-color: #0099FF;
  animation-delay: 0.3s;
}

.uploader--dot:nth-child(4) {
  background-color: #0066FF;
  animation-delay: 0.2s;
}

.uploader--dot:nth-child(5) {
  background-color: #0000CC;
  animation-delay: 0.1s;
}

.uploader--dot:nth-child(6) {
  background-color: #000080;
  animation-delay: 0s;
}

.uploader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  width: 15rem;
  margin: auto;
}

.uploader--text:after {
  content: "Uploading photo";
  font-weight: bold;
  animation-name: uploading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes uploader {
  15% {
    transform: translateX(0);
  }

  45% {
    transform: translateX(230px);
  }

  65% {
    transform: translateX(230px);
  }

  95% {
    transform: translateX(0);
  }
}

@keyframes uploading-text {
  0% {
    content: "Uploading photo";
  }

  25% {
    content: "Uploading photo.";
  }

  50% {
    content: "Uploading photo..";
  }

  75% {
    content: "Uploading photo...";
  }
}